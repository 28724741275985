@import "bootstrap/variables.less";
@import "bootstrap/mixins.less";

.main-content {
    @media (max-width: @screen-sm-min) {
        width: 100%;
        padding-right: 0px;
    }
    @media (min-width: @screen-sm-min) {
        width: 608px;
        padding-right: 0px;
    }
    @media (min-width: @screen-md-min) {
        width: 628px;
        padding-right: 15px;
    }
    @media (min-width: @screen-lg-min) {
        width: 629px;
        padding-right: 15px;
    }
}
.announces {
    @media (max-width: @screen-sm-min) {
        width: 100%;
        margin-top: 15px;
    }
    @media (min-width: @screen-sm-min) {
        width: 608px;
        margin-top: 25px;
    }
    @media (min-width: @screen-md-min) {
        width: 214px;
        margin-top: 0px;
    }
    @media (min-width: @screen-lg-min) {
        width: 424px;
        margin-top: 0px;
    }    
}
.left-col {
    .make-xs-column(12);
    .make-sm-column(12);
    .make-md-column(12);
    .make-lg-column(6);
    padding: 0px 7px 0px 0px;
}
.right-col {
    .make-xs-column(12);
    .make-sm-column(12);
    .make-md-column(12);
    .make-lg-column(6);
    padding: 0;
}
